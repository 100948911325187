.Toastify__toast--info {
  background: #b3e5fc !important;
  color: #01579b !important;
}

.Toastify__toast--success {
  background: #c8e6c9 !important;
  color: #1b5e20 !important;
}

.Toastify__toast--error {
  background: #ffcdd2 !important;
  color: #b71c1c !important;
}

.Toastify__toast--warning {
  background: #ffecb3 !important;
  color: #ff6f00 !important;
}

.Toastify__toast-theme--light {
  --toastify-color-light: #212121;
  --toastify-text-color-light: #fafafa;
}
